<template>
  <BasePage :isLoaded="!!list">
    <template v-slot:content>
      <section>
        <div class="bg-1 -below"></div>
        <div class="bg-2 -below"></div>
        <v-container class="pt-10">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="3"
              offset-md="2"
            >
              <h1 class="h2 mb-1">Event<br/>Calendar</h1>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <div class="header-spacer mb-5 mt-md-2 mb-md-5"></div>
              <h2 class="h6 text-uppercase mb-md-4">{{getCurrentLabel}}</h2>
              <div class="d-md-flex">
                <p style="min-width: 210px;">
                  <time>{{currentEvent.formattedStart}}</time> - <time>{{currentEvent.formattedEnd}}</time>
                </p>
                <MarkdownBlock
                  linkify
                  class="event-place"
                  tag="div"
                  :inline="false"
                  :text="currentEvent.place"
                />
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-4 -above">
            <v-col
              cols="12"
              md="7"
              offset-md="5"
            >
              <a v-if="currentEvent.externalLink" :href="currentEvent.externalLink" class="currentTitle -emo-gradient">
                <h2 class="h1 -emo-gradient">
                  {{currentEvent.title}}<span><Star/></span>
                </h2>
              </a>
              <h2 v-else class="currentTitle h1 -emo-gradient">
                {{currentEvent.title}}<span><Star/></span>
              </h2>
            </v-col>
          </v-row>
          <v-row v-if="currentEvent.image" class="mt-n10 -below">
            <v-col
              cols="12"
              sm="6"
              offset-sm="0"
              md="5"
              offset-md="2"
              lg="5"
              offset-lg="3"
            >
              <v-img
                height="100%"
                max-height="368px"
                width="100%"
                maxWidth="100%"
                :lazy-src="getLazySrc(currentEvent.image)"
                :src="optimizedImageURL(currentEvent.image, { width: 800, quality: 95 })"
                :aspect-ratio="getAspectRatio(currentEvent.image)"
                contain
                transition="fade-transition"
              />
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="10"
              offset-md="2"
              class="timeline-title"
              ref="timeline_title"
            >
              <h2 class="-emo-gradient">Timeline</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="10"
              offset-md="2"
              v-for="event in list"
              :key="event.id"
              class="event"
              :class="{
                current: event.isCurrent,
                past: event.isPast,
                future: event.isFuture
              }"
            >
              <div  class="timeline">
                <Star class="timeline-star"/>
              </div>
              <v-row ref="appear" class="content mb-4">
                <a v-if="event.externalLink" :href="event.externalLink" class="-above -full" :aria-label="event.title">
                </a>
                <v-col cols="12" v-if="event.isCurrent === true">
                  <h6 class="pt-1 text-uppercase">{{getCurrentLabel}}</h6>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <h3 :class="{['-emo-gradient']: event.isCurrent}">
                    {{ event.title}}
                  </h3>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="7"
                  offset-md="1"
                >
                  <MarkdownBlock
                    class="event-place pt-md-1"
                    linkify
                    tag="div"
                    :inline="false"
                    :text="event.place"
                  />
                </v-col>
                <v-col cols="12">
                  <p>
                    <time>{{event.formattedStart}}</time> - <time>{{event.formattedEnd}}</time>
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </template>
  </BasePage>
</template>

<script>
import { getLazySrc, optimizedImageURL, getAspectRatio } from '@/services/OptimizedImage'
import PageSeoQuery from '@/graphql/seo/GetEventsCalendarPageSeo.gql'
import ListEventQuery from '@/graphql/ListEvent.gql'
import { MarkdownBlock } from '@monogrid/vue-lib'
import ScrollAppear from '@/animations/gsap/scroll-appear'
import BasePage from '@/organisms/BasePage'
import Star from '@/assets/img/cursors/regi_star.svg'

export default {
  name: 'EventCalendarPage',

  components: {
    BasePage,
    MarkdownBlock,
    Star
  },

  data () {
    return {
      list: null,
      current: null
    }
  },

  apollo: {
    PageSeo: {
      query: PageSeoQuery,
      variables: {
        slug: 'globals'
      }
    },
    Events: {
      query: ListEventQuery,
      variables: {
        sortOn: 'startDate'
      },
      async result () {
        const now = Date.now()
        let current = null
        this.list = this.$apollo.data.Events.items.map((item, i) => {
          const event = { ...item }
          const start = new Date(event.startDate)
          const end = new Date(event.endDate)

          if (now > end.getTime()) {
            event.isPast = true
          } else if (now > start.getTime()) {
            current = i
            event.isCurrent = true
          } else {
            current = i
            event.isFuture = true
          }

          event.formattedStart = start.toLocaleString().split(',')[0]
          event.formattedEnd = end.toLocaleString().split(',')[0]

          const image = { ...event.image }
          image.meta = JSON.parse(image.metaData)
          image.meta.ratio = image.meta['image-width'] / image.meta['image-height']
          event.image = image

          return event
        })

        this.current = current || 0

        this.$nextTick(() => {
          this.tls = this.$refs.appear.map(el => ScrollAppear(el))
          this.tls.push(ScrollAppear(this.$refs.timeline_title))
        })
      }
    }
  },

  methods: {
    getLazySrc,
    optimizedImageURL,
    getAspectRatio
  },

  computed: {
    getCurrentLabel () {
      if (this.list[this.current].isCurrent) {
        return 'On going'
      } else if (this.list[this.current].isFuture) {
        return 'Next up'
      } else {
        return 'Latest'
      }
    },
    currentEvent () {
      return this.list[this.current]
    }
  },

  beforeDestroy () {
    this.tls.forEach(tl => tl.kill())
  },

  metaInfo: function () {
    if (this.PageSeo) {
      let meta = [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: this.PageSeo.eventsCalendarMetaTitle },
        { vmid: 'description', name: 'description', content: this.PageSeo.eventsCalendarMetaDescription },
        { vmid: 'ogdescription', property: 'og:description', content: this.PageSeo.eventsCalendarMetaDescription }
      ]

      if (this.PageSeo.eventsCalendarMetaImage) {
        meta = meta.concat([
          { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + optimizedImageURL(this.PageSeo.eventsCalendarMetaImage, { width: 1200, height: 625, format: 'jpg', fit: 'crop' }) },
          { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
          { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
          { vmid: 'ogimagewidth', property: 'og:image:height', content: '675' }
        ])
      }

      return {
        title: this.PageSeo.eventsCalendarMetaTitle,
        meta
      }
    }
  }
}
</script>

<style scoped lang="scss">

$timeline-w: rem(50px);
$timeline-md-w: rem(100px);
$star-top: rem(30px);

.bg-1,
.bg-2,
.bg-3 {
  position: absolute;
  right: 0;
  bottom: 0%;
  width: rem(1000px);
  height: rem(1000px);
  background: $bg-emo-gradient-3;

  transform: translate(50%, 50%);
  pointer-events: none;
}

.bg-1 {
  background: $bg-emo-gradient-1;

  transform: translate(30%, 20%);
}

.header-spacer {
  width: rem(80px);
  border-top: 1px solid $color-detail;
}

.currentTitle {
  span {
    padding-left: rem(4px);

    svg {
      width: rem(10px);
      height: rem(10px);
    }
  }
}

a.currentTitle {
  h2 {
    transition: transform 0.2s;
  }

  &:hover h2 {
    transform: translateX(10px);
  }
}

.event-place {
  ::v-deep p {
    margin-bottom: 0;
  }
}

.event,
.timeline-title {
  position: relative;
  padding-left: $timeline-w;
  @include breakpoint('md-and-up') {
    padding-left: $timeline-md-w;
  }
}

.event {
  padding-top: rem(16px);
  padding-bottom: rem(80px);
  @include breakpoint('md-and-up') {
    padding-bottom: rem(100px);
  }

  &.past,
  &.past h3 {
    color: #757575;
  }
}

.timeline {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: $timeline-w;

  @include breakpoint('md-and-up') {
    width: $timeline-md-w;
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: rem(1px);
    background: $color-detail;
    transform: translateX(-50%);
    z-index: 1;

    .event:first-child & {
      top: $star-top;
    }

    .event:last-child & {
      bottom: auto;
      height: $star-top;
    }
  }

  svg {
    position: relative;
    width: rem(16px);
    height: rem(16px);
    color: $color-text-dark;
    margin: $star-top auto 0;
    display: block;
    z-index: 2;

    .event.current & {
      transform: scale(1.4);
    }
  }
}

.content {
  position: relative;
  transition: transform 0.3s;
  @include breakpoint('md-and-up') {
    .event:hover & {
      transform: translateX(30px);
    }
  }
}
</style>
